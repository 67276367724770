import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { BrowserRouter, Link, Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './auth/LoginPage.tsx';
import './App.css';
import './Mobile.css';

import Feedback from './MainView/Feedback/Feedback';
import MainView from './MainView/MainView';
import Library from './MainView/Library/Library';
import { TopBar } from './TopBar/TopBar';
import { user as userService } from '../services/user';
import * as authService from '../services/auth';
import * as analytics from '../services/analytics';
import { toxinService } from '../services';
import { conditionService } from '../services/condition';
import { clinicalSignService } from '../services/clinicalsign';
import { UserManager } from './UserManager/UserManager';
import { equals } from 'ramda';
import { PasswordResetPage } from './auth/PasswordResetPage';
import { SignUpPage } from './auth/SignUpPage';
import { Legal } from './Legal/Legal';
import { AcceptTerms } from './Legal/AcceptTerms';
import FooterLogo from './FooterLogo';
import { AccountPage } from './AccountPage/AccountPage.tsx';
import { ForgotPasswordPage } from './auth/ForgotPasswordPage';

const initialState = {
  user: null,
  selectedSubstance: null,
  substanceWithDose: null,
  substances: [],
  clinicalSigns: [],
  conditions: [],
  users: [],
  config: null,
};

const globalState = U.atom(initialState);
const user = U.view('user', globalState);
const config = U.view('config', globalState);
const selectedSubstance = U.view('selectedSubstance', globalState);
const substanceWithDose = U.view('substanceWithDose', globalState);
const substances = U.view('substances', globalState);
const clinicalSigns = U.view('clinicalSigns', globalState);
const conditions = U.view('conditions', globalState);
const users = U.view('users', globalState);

const handleLogout = (history) => {
  authService.logout().then(() => {
    analytics.logout();
    user.set(null);
    selectedSubstance.set(null);
    substanceWithDose.set(null);
    substances.set([]);
    clinicalSigns.set([]);
    conditions.set([]);
    users.set([]);
    history.push('/login');
  });
};

const fetchArrayData = () => {
  toxinService
    .getToxins()
    .then((toxins) => substances.set(toxins))
    .catch(() => substances.set([]));
  if (user.get() !== null && user.get().role === 'Admin') {
    conditionService
      .getConditions()
      .then((result) => conditions.set(result))
      .catch(() => conditions.set([]));
    clinicalSignService
      .getClinicalSigns()
      .then((result) => clinicalSigns.set(result))
      .catch(() => clinicalSigns.set([]));
    userService
      .getFreeUsers('default')
      .then(R.sortBy(R.prop('id')))
      .then((result) => users.set(result))
      .catch(() => users.set([]));
  }
};

const OpenRoute = ({ render, ...rest }) =>
  user.get() === null ? (
    <Route {...rest} render={render} />
  ) : (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )}
    />
  );

const ProtectedRoute = ({ render, ...rest }) => {
  const loggedInUser = user.get();
  if (loggedInUser === null) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )}
      />
    );
  }
  return loggedInUser.termsAcceptedAt !== null ? (
    <Route {...rest} render={render} />
  ) : (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to={{
            pathname: '/acceptterms',
          }}
        />
      )}
    />
  );
};

const AdminRoute = ({ render, ...rest }) =>
  user.get() !== null && user.get().role === 'Admin' ? (
    <Route {...rest} render={render} />
  ) : (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      )}
    />
  );

export default function App({ loading, configData, initialUser }) {
  if (loading) {
    return (
      <div className={'Loading'}>
        <div id="loading-bar-spinner" className="spinner">
          <div className="spinner-icon" />
        </div>
      </div>
    );
  }
  user.set(initialUser);
  analytics.initialize(initialUser);
  config.set(configData);
  user.skipDuplicates(equals).observe((u) => {
    if (u !== null) {
      fetchArrayData();
    } else {
      substances.set([]);
      clinicalSigns.set([]);
      conditions.set([]);
      users.set([]);
    }
  });

  const footerClassname = U.mapValue((u) => (u ? '' : 'WhiteFooter'), user);
  const classNames = `App ${configData?.env}`;
  const contactUsLink =
    configData.region === 'us' ? 'https://us.toxbuddy.com/contact-us/' : 'https://www.toxbuddy.com/#lp-pom-block-64';
  const privacyPolicyLink =
    configData.region === 'us' ? 'https://us.toxbuddy.com/privacy-policy/' : 'https://www.orion.fi/en/privacy/';
  const termsOfUseLink = configData.region === 'us' ? 'https://us.toxbuddy.com/terms-of-use/' : '/legal';

  return (
    <BrowserRouter>
      <div className={classNames}>
        <TopBar
          user={user}
          handleLogout={handleLogout}
          selectedSubstance={selectedSubstance}
          currentRegion={configData.region}
        />
        <div className="Content">
          <Switch>
            <OpenRoute path="/forgot_password" render={(props) => <ForgotPasswordPage {...props} />} />
            <OpenRoute path="/passwordreset" render={(props) => <PasswordResetPage {...props} />} />
            <OpenRoute path="/activate_account" render={(props) => <PasswordResetPage {...props} />} />
            <OpenRoute path="/login" render={(props) => <LoginPage {...props} user={user} />} />
            <OpenRoute
              path="/signup"
              render={(props) => (
                <SignUpPage
                  {...props}
                  config={config}
                  user={user}
                  privacyPolicyLink={privacyPolicyLink}
                  termsOfUseLink={termsOfUseLink}
                />
              )}
            />
            <ProtectedRoute path="/feedback" render={(props) => <Feedback {...props} config={config} />} />
            <ProtectedRoute
              path="/index"
              render={(props) => <Library {...props} user={user} selectedSubstance={selectedSubstance} />}
            />
            <ProtectedRoute
              exact
              path="/"
              render={(props) => (
                <MainView
                  {...props}
                  user={user}
                  selectedSubstance={selectedSubstance}
                  substanceWithDose={substanceWithDose}
                  substances={substances}
                  clinicalSigns={clinicalSigns}
                  conditions={conditions}
                />
              )}
            />
            <ProtectedRoute
              path="/account"
              render={(props) => (
                <AccountPage {...props} config={config.get()} user={user.get()} handleLogout={handleLogout} />
              )}
            />
            <AdminRoute path="/admin" render={(props) => <UserManager {...props} users={users} />} />
            <Route path={'/legal'} component={Legal} />
            <Route
              path={'/acceptterms'}
              render={(props) => <AcceptTerms {...props} user={user} handleLogout={handleLogout} />}
            />
          </Switch>
        </div>
        <div className={'MainFooter'}>
          {U.mapValue((u) => (u ? '' : <div className={'FooterSlice'} />), user)}
          <div className={footerClassname}>
            <div className={'MainFooterContent'}>
              <div>
                {configData.region === 'us' ? (
                  <>
                    <a target={'_blank'} href={termsOfUseLink} rel="noopener noreferrer">
                      Terms of Use
                    </a>
                    &nbsp;and&nbsp;
                  </>
                ) : (
                  <>
                    <Link to={termsOfUseLink}>Terms of Use</Link>&nbsp;and&nbsp;
                  </>
                )}
                <a target={'_blank'} href={privacyPolicyLink} rel="noopener noreferrer">
                  Privacy policy
                </a>
              </div>
              <div>
                <a href="https://www.orion.fi/en/cookie-policy/" target="_blank" rel="noopener noreferrer">
                  Cookie policy
                </a>
              </div>
              <div>
                <a href={contactUsLink} target="_blank" rel="noopener noreferrer">
                  Contact us
                </a>
              </div>
              <FooterLogo config={config} />
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
